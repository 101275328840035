<template>
  <FormSection
    :icon="isActive ? 'mdi-briefcase' : 'mdi-briefcase-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Workspace Details</template>
    <template #description>
      With just a few clicks, you can create a centralized hub for all your
      digital resources, making it easy to stay organized
    </template>

    <!-- name -->

    <ValidationProvider
      v-slot="{ errors }"
      name="name"
      :rules="{ required: true }"
    >
      <TextField
        :value="name"
        label="name"
        is-mandatory
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateName"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- description -->

    <ValidationProvider
      v-slot="{ errors }"
      name="description"
      :rules="{ required: true }"
    >
      <TextAreaField
        :value="description"
        label="description"
        is-mandatory
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateDescription"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- user -->

    <ValidationProvider
      v-slot="{ errors }"
      name="users"
      :rules="{ required: false }"
    >
      <!-- user -->

      <MultiSelectField
        label="users"
        :value="users"
        :options="userList"
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateUsers"
      />

      <!-- ... -->
    </ValidationProvider>

    <!-- ... -->

    <!-- color -->

    <ColorField
      :value="color"
      label="color"
      class="q-mb-lg"
      @input="updateColor"
    />

    <!-- ... -->
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import ColorField from "@/components/common/form/color-field/ColorField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import { user } from "@/api/factory.js";

export default {
  name: "WorkspaceDetails",

  components: {
    FormSection,
    ValidationProvider,
    TextField,
    TextAreaField,
    ColorField,
    MultiSelectField,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    users: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      userList: [],
    };
  },

  created() {
    this.getUserList();
  },

  methods: {
    updateName(name) {
      this.$emit("update:name", name);
    },

    updateDescription(description) {
      this.$emit("update:description", description);
    },

    updateColor(color) {
      this.$emit("update:color", color);
    },

    updateUsers(users) {
      this.$emit("update:users", users);
    },

    async getUserList() {
      this.userList = [];
      const { error, payload } = await user.getUserList();
      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }
      this.userList =
        payload &&
        payload.map((user) => ({
          id: this.$nano.id(),
          label: user.value,
          value: user.id,
        }));
    },
  },
};
</script>

<style lang="scss" scoped></style>
